import React,{useCallback, useEffect,useState} from "react";
import './GoUp.css';
import erweima from '@/assets/ctw2.jpg';

import {useWindowSize,useWindowScroll,scrollToTop} from '@/use/diy-use.js'


const GoUp = (props) => {
    const windowSize = useWindowSize();
    const top = useWindowScroll();
    const [right,setRight] = useState(0)
 
    useEffect(() => {
        if(windowSize) {
        // console.log(`窗口宽度：${windowSize.width}`);
        // console.log(`窗口高度：${windowSize.height}`);
            if(windowSize.width>(1171+0)){
                // console.log(`窗口宽度：${windowSize.width}`);
                setRight((windowSize.width-(1171+260))/2)
            }
        }
    }, [windowSize]);

    const toUp = useCallback(()=>{
        // document.body.scrollTop=document.documentElement.scrollTop=0
        scrollToTop();
    },[])

	return (
        <div>
            <div className="goupbox" style={{'right':right+'px'}}>
                <div className="erweimabox">
                    <div>To subscribe <strong>CTW</strong><br/><span></span></div>
                    <img src={erweima} alt='' />
                </div>
                {/* <div className="notice">
                    <span>系统</span><span>通知</span>
                </div> */}
            <div className={top>300?"goup show":"goup"} onClick={toUp}></div>
            </div>
            <div className={top>300?"mgoup show":"mgoup"} onClick={toUp}></div>
        </div>
	);

};

export default GoUp;