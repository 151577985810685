import axios from 'axios'
import { Toast } from 'antd-mobile';
// create an axios instance
const service = axios.create({
  baseURL:'https://adm24.ceramictownweekly.com/api/v1',
  timeout: 8000 // request timeout
})

let current_url = "";
//请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(config)
    current_url = config.url;
    Toast.show({icon: 'loading',content: 'loading...'})
    // do something before request is sent
    // if (store.getters.token) {
      // console.log("request拦截器");
      // 让每个请求携带token--['X-Token']为自定义key
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    // }
    let token = localStorage.token||''; 
    if(config.url!=="/token"&&config.method==='post'&&token!==''){
      config.data['token'] = token;
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return error
    // return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // console.log(response.data);
    Toast.clear();
    if(response.data.errcode===200){
        return response.data;
    }else{
        console.log("出错");
        if(response.data.errmsg==="令牌验证不正确"){
          Toast.show({icon: 'fail',content: '登录过期，请重新登录！',duration:1500,afterClose:()=>{
            localStorage.clear();
            window.location.reload();
          }})
          console.log("登录过期")
        }
        return response.data;
    }
  },
  error => {
    console.log(error);
    console.log(current_url);
    Toast.clear();
    Toast.show({icon: 'fail',content: '网络异常，请稍后再试！'})

    // window.location.reload();
    
    return {errmsg:"网络异常，请稍后再试！"};
  }
)


axios.jsonp = (url,data)=>{
  if(!url)
      throw new Error('url is necessary')
  // const callback = 'CALLBACK' + Math.random().toString().substr(9,18)
  const callback = 'renderReverse';
  const JSONP = document.createElement('script')
        JSONP.setAttribute('type','text/javascript')

  const headEle = document.getElementsByTagName('head')[0]
  
  // JSONP.src = `${url}?callback=${callback}${ret}`;
  JSONP.src = url;

  console.log(JSONP.src);
  return new Promise( (resolve,reject) => {
      window[callback] = r => {
        // console.log(r);
        resolve(r)
        headEle.removeChild(JSONP)
        delete window[callback]
      }
      headEle.appendChild(JSONP)
  })
}


export default service
