import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counterSlice'
import userReducer from './userSlice'
import newSlice from './newSlice'
import exhibitSlice from './exhibitSlice'






export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user:userReducer,
    news:newSlice,
    exhibit:exhibitSlice
  },
})