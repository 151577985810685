const routes = [
    {
        path: '/',
        component: () => import('@/layouts/base.jsx'),
        children: [
            {
                path: '/',
                redirect: '/index',
            },
            {
                path: '/index',
                component: () => import('@/views/index'),
                meta: {
                    title: "CTW",
                    needLogin:false
                }
            },
            {
                path: '/news',
                component: () => import('@/views/news'),
                meta: {
                    title: "CTW-新闻",
                }
            },
            {
                path: '/video',
                component: () => import('@/views/video'),
                meta: {
                    title: "CTW-视频",
                }
            },
            {
                path: '/quote',
                component: () => import('@/views/quote'),
                meta: {
                    title: "CTW-市场",
                }
            },
            {
                path: '/sell',
                component: () => import('@/views/sell'),
                meta: {
                    title: "CTW-技术/供应商",
                }
            },
            {
                path: '/exhibit',
                component: () => import('@/views/exhibit'),
                meta: {
                    title: "CTW-展会",
                }
            },
            {
                path: '/rank',
                component: () => import('@/views/rank'),
                meta: {
                    title: "CTW-百强",
                }
            },
            {
                path: '/search',
                component: () => import('@/views/search'),
                meta: {
                    title: "CTW-搜索",
                }
            },
            {
                path: '*',
                component: () => import('@/views/index'),
                meta: {
                    title: '404'
                }
            }
        ],
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        meta: {
            title: "CTW-登录",
        }
    },
    {
        path: '/me',
        component: () => import('@/views/me'),
        meta: {
            title: "CTW-个人中心",
            needLogin:true
        }
    },
    {
        path: '/register',
        component: () => import('@/views/me/register.jsx'),
        meta: {
            title: "CTW-注册",
            needLogin:false
        }
    },
    {
        path: '/base',
        component: () => import('@/views/me/base.jsx'),
        meta: {
            title: "CTW-编辑个人信息",
            needLogin:false
        }
    },
    {
        path: '/show/:id',
        component: () => import('@/views/show'),
        meta: {
            title: "CTW-新闻",
            needLogin:false
        }
    },
    {
        path: '/vshow/:id',
        component: () => import('@/views/video/vshow.jsx'),
        meta: {
            title: "CTW-视频",
            needLogin:false
        }
    }
]

export default routes;