import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getNewsCategory,getNewsRecommend,getNews,getNewsInformation,getVideo} from '@/api/index.js'


const initialState = {
  typeArr:[],
  recommendList:[],
  informationList:[],
  indexExpoList:[],
  expoList:{},
  indexVideoList:[],
  videoList:{},
  newsList:{}
}

//获取新闻分类
export const getNewsType = createAsyncThunk('news/getType', async (param) => {
    const payload = await getNewsCategory()
    return payload
})
// 获取首页新闻推荐
export const getNewsRec = createAsyncThunk('news/getRec', async (param) => {
    const payload = await getNewsRecommend()
    return payload
})
// 获取首页新闻资讯 首屏4条新闻
export const getNewsInfo = createAsyncThunk('news/getInfo', async (param) => {
    const payload = await getNewsInformation()
    return payload
})
// 获取首页展会第一页
export const getIndexExpo = createAsyncThunk('news/getIndexExpo', async (param) => {
    const payload = await getNews({page:1,cid:4,number:5})
    return payload
})

// 获取首页视频第一页
export const getIndexVideo = createAsyncThunk('news/getIndexVideo', async (param) => {
    const payload = await getVideo({page:1,number:5})
    return payload
})
// 获取新闻列表
export const getNewsList = createAsyncThunk('news/getNewsList', async (param) => {
    console.log(param)
    const payload = await getNews(Object.assign({number:5},param))
    return payload
})
// 获取视频列表
export const getVideoList = createAsyncThunk('news/getVideoList', async (param) => {
    console.log(param)
    const payload = await getVideo(Object.assign({number:5},param))
    return payload
})
// 获取展会列表
export const getExhibitList = createAsyncThunk('news/getExhibitList', async (param) => {
    console.log(param)
    const payload = await getNews(Object.assign({cid:4,number:5},param))
    return payload
})



export const newSlice = createSlice({
  name: 'new',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsType.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.typeArr = payload.data);
            // state.total = payload.total;
        })
        .addCase(getNewsRec.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.recommendList = payload.data);
        })
        .addCase(getNewsInfo.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.informationList = payload.data);
        })
        .addCase(getIndexExpo.fulfilled, (state, { payload }) => {
            // console.log(payload)
            if(payload.errcode===200){
                state.indexExpoList = payload.data.news_list;
                //首页获取展会新闻第一页，如果此时展会页面没数据，可以填充给展会列表
                if(JSON.stringify(state.expoList) === "{}"){
                    state.expoList = payload.data;
                }
            }
        })
        .addCase(getIndexVideo.fulfilled, (state, { payload }) => {
            // console.log(payload)
            if(payload.errcode===200){
                state.indexVideoList = payload.data.video_list;
                 //首页获取视频新闻第一页，如果此时视频页面没数据，可以填充给视频页面
                if(JSON.stringify(state.videoList) === "{}"){
                    state.videoList = payload.data;
                }
            }
        })
        .addCase(getNewsList.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.newsList = payload.data);
        })
        .addCase(getVideoList.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.videoList = payload.data);
        })
        .addCase(getExhibitList.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.expoList = payload.data);
        })
    }
})

// Action creators are generated for each case reducer function
export const { setUser,setCollect,setToken,openLoginBox,closeLoginBox } = newSlice.actions

export default newSlice.reducer