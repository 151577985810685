import './DHeader.css';
import React,{useCallback, useEffect,useRef} from "react";
import { useSelector, useDispatch } from 'react-redux'
// import { decrement, increment,incrementByAmount } from '@/stores/counterSlice'
import { openLoginBox,closeLoginBox } from '@/stores/userSlice'
import {useWindowScroll} from '@/use/diy-use.js'



// import Language from './Language.js'
import logo2024 from '@/assets/CTWlogo.jpg'
import user from '@/assets/user.svg';
import userDefault from '@/assets/icon-user-default.jpg';
import search from '@/assets/search.svg';
import twitter from '@/assets/icon-t.png';
import facebook from '@/assets/icon-facebook.png';
import youtube from '@/assets/icon-yg.png';
import {useNavigate} from 'react-router-dom';
import Search from './Search';


// import  IconSearch from '@/components/icons/IconSearch.jsx'

import { HashRouter as Router, NavLink } from "react-router-dom";

export default function DHeader(props){
	const myHeader = useRef(null);
	const top = useWindowScroll();

	useEffect(() => {
		if(top<48 && window.innerWidth<500){
			myHeader.current.style.top = -top+"px"
		}
		if(top>48 && window.innerWidth<500){
			myHeader.current.style.top = "-49px"
		}
		if(top<1 && window.innerWidth<500){
			myHeader.current.style.top = "0px"
		}
		if(window.innerWidth>500){
			myHeader.current.style.top = "0px"
		}
	}, [top]);

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 	  let windowScroll = window.scrollY
	// 	  if(windowScroll<48 && window.innerWidth<500){
	// 		  myHeader.current.style.top = -windowScroll+"px"
	// 	  }
	// 	  if(windowScroll>48 && window.innerWidth<500){
	// 		  myHeader.current.style.top = "-48px"
	// 	  }
	// 	  if(windowScroll<1 && window.innerWidth<500){
	// 		  myHeader.current.style.top = "0px"
	// 	  }
	// 	};
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 	  window.removeEventListener("scroll", handleScroll);
	// 	};
	//   }, []);

	const navigate = useNavigate();
	const onSearch = ()=>{
		navigate('/search',{replace:true})
		// dispatch(incrementByAmount(10))
	}
	// const count = useSelector((state) => state.counter.value)
	const token = useSelector((state) => state.user.token);
	const loginBox = useSelector((state) => state.user.loginBox);

  	const dispatch = useDispatch()

	const toLogin = useCallback(()=>{
		if(token!==''){
			navigate('/me',{replace:true})
		}else{
			if(loginBox){
				console.log("关闭登录")
				dispatch(closeLoginBox())
			}else{
				console.log("打开登录")
				dispatch(openLoginBox())
			}
		}
	},[navigate,token,loginBox,dispatch])

	return (
		<header ref={myHeader} style={{'top':'0px'}}>
			<div className='wrap mobile-hide'>
				{/* pc端 */}
				<div className='flexbox'>
					<NavLink to="/index" style={{fontSize:'0'}}><img className="ctwlogo" alt="logo" src={logo2024} /></NavLink>
					<div className='navbox'>
							<NavLink to="/index">Home</NavLink>
							<NavLink to="/news">News</NavLink>
							<NavLink to="/video">Videos</NavLink>
							{/* <NavLink to="/quote">Market</NavLink>
							<NavLink to="/sell">Technology/Suppliers</NavLink> */}
							<NavLink to="/exhibit">Fairs</NavLink>
							{/* <NavLink to="/rank">Global 100</NavLink> */}
					</div>

					{/* <div className='search-box'>
						<input type='text' />
						<span className="clearSearch"></span>
						<div className='svgbox' style={{position: "relative", width: "16px",height: "16px", overflow: "hidden"}} onClick={onSearch}>
							<img src={search} alt='' style={{width:"16px",height:"16px", position:"absolute", left: "-20px", filter: "drop-shadow(20px 0 #25323a)" }} />
						</div>
					</div> */}
					<Search></Search>

					<div className="follow-us">
						<a href="https://twitter.com/CeramicTWeekly" target="_blank"><img src={twitter} /></a>
						<a href="https://www.facebook.com/ceramictownweekly" target="_blank"><img src={facebook} /></a>
						<a href="https://www.youtube.com/channel/UCuRPCkjpt4trT9Fnoe0B60A" target="_blank"><img src={youtube} /></a>
					</div>

					<div className='loginbtnbox'>
						{token===''&&(<span className='a' onClick={toLogin}>
							<div className='svgbox' style={{position: "relative", width: "20px",height: "20px", overflow: "hidden"}} title='login'>
								<img src={user} alt='' style={{ position:"absolute", left: "-20px", filter: "drop-shadow(20px 0 #25323a)" }} />
							</div>
						</span>)}
						{token!==''&&(<span className='a' onClick={toLogin}>
							<img className='userlogo' src={userDefault} alt='user' />
						</span>)}
					</div>
				</div>
			</div>
			{/* 移动端 */}
			<div className='wrap pc-hide'>
				<div className='mobilebox'>
					<div className='flexbox'>
						<NavLink to="/index" style={{fontSize:'0'}}><img className='ctwlogo' alt="logo" src={logo2024} /></NavLink>
						<div className='rightbox'>

							{/* <div className='search-box'>
								<input type='text' />
								<span className="clearSearch" ></span>
								<div className='svgbox' style={{position: "relative", width: "16px",height: "16px", overflow: "hidden"}} onClick={onSearch}>
									<img src={search} alt='' style={{width:"16px",height:"16px", position:"absolute", left: "-20px", filter: "drop-shadow(20px 0 #25323a)" }} />
								</div>
							</div> */}
							<Search></Search>


							<div className="follow-us">
								<a href="https://twitter.com/CeramicTWeekly" target="_blank"><img src={twitter} /></a>
								<a href="https://www.facebook.com/ceramictownweekly" target="_blank"><img src={facebook} /></a>
								<a href="https://www.youtube.com/channel/UCuRPCkjpt4trT9Fnoe0B60A" target="_blank"><img src={youtube} /></a>
							</div>
							<div className='loginbtnbox'>
								{token===''&&(<span className='a' onClick={toLogin}>
									<div className='svgbox' style={{position: "relative", width: "22px",height: "22px", overflow: "hidden"}}>
										<img src={user} alt=''  style={{ position:"absolute", left: "-22px", filter: "drop-shadow(22px 0 #25323a)" }} />
									</div>
								</span>)}
								{token!==''&&(<span className='a' onClick={toLogin}>
									<img className='userlogo' src={userDefault} alt='user' />
								</span>)}
							</div>
						</div>
					</div>
					<div className='flexbox'>
						<div className='navbox'>
								<NavLink to="/index">Home</NavLink>
								<NavLink to="/news">News</NavLink>
								<NavLink to="/video">Videos</NavLink>
								{/* <NavLink to="/quote">Market</NavLink>
								<NavLink to="/sell">Technology/Suppliers</NavLink> */}
								<NavLink to="/exhibit">Fairs</NavLink>
								{/* <NavLink to="/rank">Global 100</NavLink> */}
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};