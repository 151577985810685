import service from '../service.js';

// 1.1 获取token令牌
export function getToken(params){
    return service({
        url: '/token',
        method: 'post',
        data: params
    })
}
// 1.2 注册会员信息
export function subRegister(params){
    return service({
        url: '/member/register',
        method: 'post',
        data: params
    })
}
// 1.3 获取会员收藏新闻列表
export function getCollectList(params){
    return service({
        url: '/member/collection',
        method: 'post',
        data: params
    })
}
// 1.4 会员添加新闻收藏 {news_id}
export function subCollect(params){
    return service({
        url: '/member/collection/save',
        method: 'post',
        data: params
    })
}
// 1.5 会员取消新闻收藏 {news_id}
export function removeCollect(params){
    return service({
        url: '/member/collection/delete',
        method: 'post',
        data: params
    })
}
// 1.6 会员信息编辑
export function updateMember(params){
    return service({
        url: '/member/update',
        method: 'post',
        data: params
    })
}


// 2.1 新闻分类获取
export function getNewsCategory(params){
    return service({
        url: '/news/category',
        method: 'get',
        params: params
    })
}
// 2.2 新闻列表获取
export function getNews(params){
    // console.log(params)
    return service({
        url: '/news',
        method: 'get',
        params: params
    })
}
// 2.3 推荐新闻列表获取
export function getNewsRecommend(params){
    return service({
        url: '/news/recommend',
        method: 'get',
        params: params
    })
}
// 2.4 首页新闻资讯
export function getNewsInformation(params){
    return service({
        url: '/news/information',
        method: 'get',
        params: params
    })
}
// 2.5 获取新闻详情{news_id}
export function getNewsDetail(params){
    return service({
        url: '/news/read',
        method: 'post',
        data: params
    })
}


//视频分类获取 /video/category


// 3.2 视频列表获取
export function getVideo(params){
    return service({
        url: '/video',
        method: 'get',
        params: params
    })
}
// 3.3 获取视频详情
export function getVideoDetail(params){
    return service({
        url: '/video/read',
        method: 'post',
        data: params
    })
}


// 4.1 展会相册获取
export function getExpoAlbum(params){
    return service({
        url: '/expo/album',
        method: 'get',
        params: params
    })
}

// 5.1 全局搜索
export function getSearch(params){
    return service({
        url: '/search',
        method: 'get',
        params: params
    })
}

// 6.1 提交留言信息
export function subMessage(params){
    return service({
        url: '/leave/word/save',
        method: 'post',
        data: params
    })
}